/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// import './debugger';
import {
	appWithBrowserRouter,
	ModuleFE_ActionProcessor,
	ModuleFE_LocalStorage,
	ModuleFE_Notifications, ModuleFE_StorageCleaner,
	ModuleFE_Window,
	ModuleFE_XHR, Storage_AppVersion,
	Thunder,
} from '@nu-art/thunderstorm/frontend';
import {App} from './app/App';
import {compareVersions, generateHex, Module} from '@nu-art/ts-common';
import {ModulePackFE_Advisor, StorageKey_AdvisorEncounterId, StorageKey_AdvisorSessionId, StorageKey_EHRInstanceId} from '@app/advisor/frontend';
import {ModuleFE_Theme} from '@app/styles';
import {AdvisorApplicationName} from '@app/advisor';
import {ModuleFE_SyncManager} from '@nu-art/thunderstorm/frontend/modules/sync-manager/ModuleFE_SyncManager';
import {ModuleFE_Workspace} from '@nu-art/ts-workspace/frontend';
import {defaultWorkspaceConfigs} from '@app/advisor/frontend/ui/workspace-configs';
import {StorageKey_AdvisorAccountId} from './app/consts';
import {ModuleFE_FirebaseListener} from '@nu-art/firebase/frontend/ModuleFE_FirebaseListener/ModuleFE_FirebaseListener';
import {ModulePack_AdvisorStatic} from '@app/advisor/frontend/modules/static-modules/module-pack';
import {HeaderKey_Application} from '@nu-art/thunderstorm/shared/headers';
import {ModulePackFE_AdvisorUI} from '@app/advisor-v5/frontend';

const modules: Module[] = [
	//TS
	ModuleFE_FirebaseListener,
	ModuleFE_XHR,
	ModuleFE_Theme,
	ModuleFE_Window,
	ModuleFE_Notifications,
	ModuleFE_ActionProcessor,
	ModuleFE_SyncManager,
	ModuleFE_LocalStorage,
	ModuleFE_Workspace,
];

ModuleFE_XHR.addDefaultHeader(HeaderKey_Application, () => AdvisorApplicationName);
ModuleFE_Workspace.setAccountResolver(() => StorageKey_AdvisorAccountId.get(generateHex(8)));
ModuleFE_Workspace.setDefaultConfig({defaultConfigs: defaultWorkspaceConfigs});

const prevAppVersion = Storage_AppVersion.get();
const appVersion = require('./version-app.json').version as string;

const sessionId = StorageKey_AdvisorSessionId.get();
const encounterId = StorageKey_AdvisorEncounterId.get();
const ehrInstanceId = StorageKey_EHRInstanceId.get();


new Thunder()
	.addModulePack(modules)
	.addModulePack(ModulePackFE_Advisor)
	.addModulePack(ModulePack_AdvisorStatic)
	.addModulePack(ModulePackFE_AdvisorUI)
	.setConfig(require('./config').config)
	.setMainApp(App, appWithBrowserRouter)
	.setVersion(appVersion)
	.addPreBuildAction(async () => {
		if (compareVersions(appVersion, prevAppVersion ?? '0.0.0') === -1)
			await ModuleFE_StorageCleaner.cleanAll(() => {
				Storage_AppVersion.set(appVersion);
				if (sessionId)
					StorageKey_AdvisorSessionId.set(sessionId);
				if (encounterId)
					StorageKey_AdvisorEncounterId.set(encounterId);
				if (ehrInstanceId)
					StorageKey_EHRInstanceId.set(ehrInstanceId);
			});
	})
	.build(async () => {
	});