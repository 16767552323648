/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {AppPage, LL_V_C, ModuleFE_Routing, ModuleFE_RoutingV2, TS_DialogOverlay, TS_PopUp, TS_ToastOverlay, TS_ToolTip} from '@nu-art/thunderstorm/frontend';
import * as React from 'react';
import './App.scss';
import {AdvisorRoutes, RouteKey} from './Routes';
import {DevPage} from './DevPage';

const pageNameMap: { [k in RouteKey]?: string } = {
	'demo': 'Advisor Demo',
	'advisor': 'Advisor',
	'launch-smart': 'Launch Smart on FHIR'
};

export class App
	extends React.Component<{}> {

	public static dropBlocker<T>(ev: React.DragEvent<T>) {
		ev.preventDefault();
		ev.stopPropagation();
	}

	render() {
		return <LL_V_C
			onContextMenu={App.dropBlocker}
			onMouseUp={App.dropBlocker}
			onDrop={App.dropBlocker}
			onDragOver={App.dropBlocker}
			className={'app'}>
			<AppRenderer/>
			<TS_DialogOverlay/>
			<TS_ToastOverlay/>
			<TS_PopUp/>
			<TS_ToolTip/>
		</LL_V_C>;
	}
}

class AppRenderer
	extends AppPage {

	constructor(p: any) {
		super(p);
		const route = ModuleFE_Routing.getMyRouteKey();
		document.title = (pageNameMap[route as RouteKey]!);
	}

	__onLoginStatusUpdated() {
		this.forceUpdate();
	}

	protected deriveStateFromProps(nextProps: {}, state?: Partial<{}> | undefined) {
		return {};
	}

	render() {
		const config = require('../config').config;
		const routes = AdvisorRoutes;
		if (config.label.toLowerCase() === 'local')
			routes.children?.push(DevPage.Route);

		return ModuleFE_RoutingV2.generateRoutes(routes);
	}
}