/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from 'react';
import {ModuleFE_RoutingV2, TS_Route} from '@nu-art/thunderstorm/frontend';
import * as Routes from './route-keys';
import {Page_Physician, Page_SmartOnFhir, QuaiAdvisorPageLoader} from '@app/advisor/frontend';
import {Page_Sandbox} from '@app/advisor/frontend/ui/sandbox';
import {ComponentValidateSession} from '@app/advisor/frontend/ui/components/ComponentValidateSession';
import {moduleToAwait} from '@app/advisor/frontend/ui/V4/Page_Advisor_V4/module-to-await';
import {Page_Advisor} from '@app/advisor-v5/frontend/ui';
import {Page_DemoV2} from '@app/advisor/frontend/ui/pages/Page_Demo';


export type RouteKey =
	typeof Routes.Route_Home |
	typeof Routes.Route_Demo |
	typeof Routes.Route_Advisor |
	typeof Routes.Route_BroadcastTarget |
	typeof Routes.Route_BroadcastHub |
	typeof Routes.Route_LaunchSmartOnFhir |
	typeof Routes.Route_Advisor_V4 |
	typeof Routes.RouteKey_AdvisorWrapper;

const config = require('../config').config;

const Route_AdvisorWrapper: TS_Route = {
	key: Routes.RouteKey_AdvisorWrapper,
	path: 'advisor-wrapper',
	index: ['LOCAL', 'USABILITY 2024'].includes(config.label),
	Component: Page_DemoV2
};

export const AdvisorRoutes: TS_Route = {
	key: Routes.Route_Home,
	path: '/',
	index: !['USABILITY 2023', 'LOCAL'].includes(config.label),
	fallback: true,
	children: [
		Route_AdvisorWrapper,
		{
			key: Routes.Route_Advisor,
			path: 'advisor',
			Component: ComponentValidateSession,
			children: [
				{
					key: 'physician',
					path: 'physician',
					fallback: true,
					Component: Page_Advisor,
					modulesToAwait: moduleToAwait,
					awaitModulesLoader: (param) => <QuaiAdvisorPageLoader
						text={'Loading Advisor'}
						onClick={param.onClick}
						onDoubleClick={() => {
							// @ts-ignore
							toggleLogger();
						}}
					/>
				},
				{
					key: 'nurse', path: 'nurse', fallback: true, Component: Page_Advisor, modulesToAwait: moduleToAwait,
					awaitModulesLoader: (param) => <QuaiAdvisorPageLoader
						text={'Loading Advisor'}
						onClick={param.onClick}
						onDoubleClick={() => {
							// @ts-ignore
							toggleLogger();
						}}
					/>
				},
			]
		},
		{
			key: Routes.Route_Demo,
			index: true,
			path: 'demo',
			Component: () => ModuleFE_RoutingV2.redirect(Route_AdvisorWrapper),
		},
		{key: Routes.Route_LaunchSmartOnFhir, path: 'launch-smart', Component: Page_SmartOnFhir},
		{key: Routes.Route_Advisor_V4, path: 'advisor-v4', Component: Page_Physician},
		Page_Sandbox.Route,
	]
};