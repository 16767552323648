//General
export const Route_Home = 'home';
export const Route_Demo = 'demo';
export const Route_BroadcastTarget = 'boardcast-target';
export const Route_BroadcastHub = 'broadcast-hub';
export const RouteKey_AdvisorWrapper = 'advisor-wrapper';

//Advisor
export const Route_Advisor = 'advisor';
export const Route_LaunchSmartOnFhir = 'launch-smart';
export const Route_Advisor_V4 = 'advisor-v4';